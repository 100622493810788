import styled from "@emotion/styled";
import Colors from "styles/colors";
import { UI_BORDER_LIGHT_COLOR_NAME } from "styles/color-consts";
export const StyledBottomItemsComponent = styled.div`
  display: flex;
  width: 375px;
  justify-content: center;

  .bottom-items {
    display: flex;
    align-items: center;

    .separator {
      width: 1px;
      height: 50%;
      background: ${Colors[UI_BORDER_LIGHT_COLOR_NAME]};
    }

    .header-menu-section-item-component-wrapper {
      width: auto;
      height: auto;
      padding: 0 16px;
      margin: 0 8px;

      .header-menu-section-item-component {
        font-size: 0.8125rem;
        color: ${Colors.asphalt};
        display: flex;
        align-items: center;
        gap: 8px;
        height: 24px;

        .header-menu-item-content {
          align-items: center;

          .menu-item-title-icon {
            margin-right: 8px;
            height: 14px;
          }
        }
      }
    }
  }
`;
