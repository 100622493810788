import styled from "@emotion/styled";
import Colors from "styles/colors";
import { HEADER_DESKTOP_MENU_MID_WIDTH, HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH, MD_DESKTOP_WIDTH } from "/constants/vp-sizes";
import { BACKGROUND_GRAY_COLOR_NAME, LIGHT_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { getComponentShadowFilter } from "segments/desktop/constants";
import { SECTION_ITEM_HEIGHT, SECTION_ITEM_HORIZONTAL_MARGIN, SECTION_ITEM_SMALL_HORIZONTAL_MARGIN, SECTION_ITEM_WIDTH } from "../constants";
const SECTION_ITEM_HEIGHT_PX = `${SECTION_ITEM_HEIGHT}px`;
const SECTION_ITEM_WIDTH_PX = `${SECTION_ITEM_WIDTH}px`;
const SECTION_ITEM_HORIZONTAL_MARGIN_PX = `${SECTION_ITEM_HORIZONTAL_MARGIN}px`;
const SECTION_ITEM_SMALL_HORIZONTAL_MARGIN_PX = `${SECTION_ITEM_SMALL_HORIZONTAL_MARGIN}px`;
export const StyledHeaderMenuSectionItemComponent = styled.div`
  width: ${SECTION_ITEM_WIDTH_PX};
  height: ${SECTION_ITEM_HEIGHT_PX};
  margin-right: ${SECTION_ITEM_HORIZONTAL_MARGIN_PX};
  padding: 16px 8px;
  background-color: var(--header-menu-section-item-background-color);
  &:hover {
    background-color: var(--header-menu-section-item-hover-background-color);
  }

  &.outline {
    border-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
  }

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  &:not(.outline) {
    border-color: transparent;
  }
  &.secondary-product {
    width: 340px;
    .menu-item-title {
      opacity: 0.85;
    }
  }
  &.feature {
    width: 280px;
    margin-bottom: 14px;
    margin-right: 14px;
    padding: 16px 12px;
    .menu-item-title-icon {
      background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
      border: 1px solid transparent;
      border-radius: 2px;
      margin-right: 12px;
    }
    &:hover {
      .menu-item-title-icon {
        border: ${`1px solid ${Colors[LIGHT_WORK_OS_IRIS_COLOR_NAME]}`};
      }
    }
  }
  &.resource {
    width: 274px;
    margin-bottom: 14px;
    margin-right: 28px;
  }
  &.story {
    height: unset;
    width: 356px;
    padding: 0;
    border: none;
    &:hover {
      ${getComponentShadowFilter()}
      // remove hover effect in safari because of this bug: https://monday.monday.com/boards/757661511/pulses/2181485781
            @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          filter: unset;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: 147px;
      background: ${Colors.silver};
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .menu-item-text-wrapper {
      padding: 8px 20px 16px;
      .menu-item-description {
        font-size: 0.8125rem;
        line-height: 19px;
        color: ${Colors.mud};
      }
    }
  }
  .menu-item-text-and-tags-wrapper {
    display: flex;
    align-items: center;
  }
  &.by-team {
    .header-menu-section-item-component {
      .header-menu-item-content {
        .menu-item-title-icon {
          margin-top: -4px;
          margin-right: 8px;
        }
      }
    }
  }
  &.secondary-product {
    width: 156px;
    height: 54px;
    margin-bottom: 12px;
    .header-menu-section-item-component .header-menu-item-content .menu-item-text-wrapper .menu-item-description {
      -webkit-line-clamp: 3;
    }
  }

  .header-menu-section-item-component {
    display: flex;
    height: 100%;
    width: 100%;
    cursor: pointer;
    align-items: center;
    transition: background-color 200ms ease;
    text-decoration: none;
    color: ${Colors.black};
    &:not([href]) {
      cursor: default;
    }
    .header-menu-item-content {
      display: flex;

      &.has-image-instead-title {
        flex-direction: column;
      }

      .menu-item-title-icon {
        flex-shrink: 0;
        font-size: 0.875rem;
        margin-right: 16px;

        &:not(.fill) {
          path,
          rect,
          circle {
            stroke: var(--icon-color);
          }
        }
      }

      .menu-item-text-wrapper {
        display: flex;
        flex-direction: column;

        .menu-item-title-wrapper {
          display: flex;

          .menu-item-title {
            font-size: 0.875rem;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 20px;
          }

          .new-badge-component-wrapper {
            margin-left: 8px;
            display: flex;
            align-items: center;
          }
        }

        .menu-item-description {
          margin-top: 8px;
          font-size: 0.8125rem;
          line-height: 20px;
          color: ${Colors.asphalt};
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
        .menu-item-description-button {
          margin-top: 16px;
          .secondary-button {
            font-size: 0.8125rem;
          }
        }
      }
    }

    @media (max-width: ${HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH}) {
      margin-right: ${SECTION_ITEM_SMALL_HORIZONTAL_MARGIN_PX};
    }
  }

  @media (max-width: ${MD_DESKTOP_WIDTH}) {
    &.story {
      width: 280px;
      img {
        height: 115px;
      }
    }
  }

  @media (max-width: ${HEADER_DESKTOP_MENU_MID_WIDTH}) {
    &.story {
      width: 240px;
      img {
        height: 99px;
      }
    }
  }
`;
