import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01 } from "styles/color-consts";
export const StyledHeaderMenuSectionComponent = styled.div`
  height: 100%;

  .header-menu-section-title {
    color: ${Colors.asphalt};
    font-weight: 300;
    font-size: 0.8125rem;
    display: flex;
    align-items: center;
    position: relative;

    &.with-underline {
      font-size: 1rem;

      &:after {
        content: "";
        background: ${Colors.mud};
        opacity: 0.1;
        top: 24px;
        display: block;
        position: absolute;
        height: 1px;
        width: calc(100% - 20px);
        margin-left: -8px;
      }
    }

    &.with-image-and-CTA {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-left: -16px;
      padding: 12px 16px;
      border-bottom: 1px solid ${Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]};

      &:hover {
        border-radius: 4px;
        border-color: transparent;
        background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
        cursor: pointer;
      }

      .secondary-button {
        font-size: 0.8125rem;
      }
    }

    .header-menu-section-title-line {
      margin-right: 8px;
      stroke: ${Colors.asphalt};
    }

    .header-menu-section-title-image-and-cta {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .header-menu-section-title-image {
        height: 26px;
        display: flex;
      }

      .secondary-button {
        margin-right: 18px;
      }
    }
  }

  .header-menu-section-items-wrapper {
    margin: 20px 0;

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(var(--max-number-of-items-in-column), auto);
  }

  .header-menu-see-more-wrapper {
    margin-bottom: 16px;
    display: flex;

    .secondary-button {
      .secondary-button-text {
        font-size: 0.8125rem;
      }
    }
  }
`;
