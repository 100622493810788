import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledSocialProofComponent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${Colors.asphalt};
  gap: 8px;

  .stars {
    display: flex;
  }

  .logo-wrapper {
    height: 19px;
    display: flex;
    align-items: center;
  }

  .reviews-text {
    white-space: nowrap;
  }
`;
