export const SECTION_ITEM_HEIGHT = 98;
export const SECTION_ITEM_WIDTH = 286; // includes 16 pixel padding (8 on each side)
export const SECTION_ITEM_HORIZONTAL_MARGIN = 18;
export const SECTION_ITEM_SMALL_HORIZONTAL_MARGIN = 8;
export const SEPERATOR_RIGHT_MARGIN = 32;
export const SEPERATOR_LEFT_MARGIN = 4;
export const SEPERATOR_RIGHT_MARGIN_SMALL = 16;
export const SEPERATOR_LEFT_MARGIN_SMALL = 4;
export const SECTION_MARGIN_BETWEEN_COLUMNS = 8;
export const SECTION_SMALL_MARGIN_BETWEEN_COLUMNS = 0;
export const SECTION_ITEM_BOTTOM_MARGIN = 38;
