import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledBaseHeaderMenuComponent = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 18px 30px rgba(0, 0, 0, 0.03);
  padding: 16px 0px;

  .header-menu-items-wrapper {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding: 0px 32px;

    .header-menu-item-component-wrapper {
      height: 156px;
      width: 20%;
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    .header-menu-items-wrapper {
      .header-menu-item-component-wrapper {
        width: 25%;
      }
    }
  }
`;
