import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
export const StyledStructuredHeaderMenuBottomPartComponent = styled.div`
  display: flex;
  padding: 8px 0;
  border-top: ${`1px solid ${Colors[BACKGROUND_GRAY_COLOR_NAME]}`};

  .spacer {
    flex: 1;
  }
`;
