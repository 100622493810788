import { AboutIcon, AppDevelopmentIcon, BlogIcon, CertificationIcon, CustomersStoryIcon, HelpCenterIcon, PartnersIcon, SupportIcon, TemplateCenterIcon, WhatsNewIcon } from "./icons/index.js";
import { ABOUT_US_PAGE_LINK, APPS_DEVELOPER_LINK, BLOG_PAGE_LINK, BUILDERS_CERTIFICATIONS_PAGE_LINK, CUSTOMERS_PAGE_LINK, HELP_CENTER_LINK, PARTNERS_PAGE_LINK, SUPPORT_PAGE_LINK, TEMPLATE_CENTER_PAGE_LINK, WHATS_NEW_PAGE_LINK } from "constants/links";
export const LEARN_LINKS = [
    {
        Icon: AboutIcon,
        text: "About us",
        link: ABOUT_US_PAGE_LINK
    },
    {
        Icon: WhatsNewIcon,
        text: "What’s new",
        link: WHATS_NEW_PAGE_LINK
    },
    {
        Icon: BlogIcon,
        text: "Blog",
        link: BLOG_PAGE_LINK
    },
    {
        Icon: CertificationIcon,
        text: "Get certified",
        link: BUILDERS_CERTIFICATIONS_PAGE_LINK
    },
    {
        Icon: CustomersStoryIcon,
        text: "Customer stories",
        link: CUSTOMERS_PAGE_LINK
    }
];
export const SUPPORT_LINKS = [
    {
        Icon: HelpCenterIcon,
        text: "Help center",
        link: HELP_CENTER_LINK
    },
    {
        Icon: SupportIcon,
        text: "24/7 support",
        link: SUPPORT_PAGE_LINK
    },
    {
        Icon: PartnersIcon,
        text: "Partners",
        link: PARTNERS_PAGE_LINK
    }
];
export const BUILD_LINKS = [
    {
        Icon: TemplateCenterIcon,
        text: "Template center",
        link: TEMPLATE_CENTER_PAGE_LINK
    },
    {
        Icon: AppDevelopmentIcon,
        text: "App development",
        link: APPS_DEVELOPER_LINK
    }
];
