import { WORK_MANAGEMENT_MINI_SITE_LINK, CRM_MINI_SITE_LINK, SOFTWARE_MINI_SITE_LINK, SERVICE_MINI_SITE_LINK, WORKFORMS_HOMEPAGE_LINK, CANVAS_HOMEPAGE_LINK } from "constants/links";
export const MAIN_PRODUCTS = [
    {
        name: "Work Management",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/WM.png",
        miniSiteLink: WORK_MANAGEMENT_MINI_SITE_LINK,
        productStoreLink: "monday.com/account_products/direct_install/core"
    },
    {
        name: "CRM",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/CRM.png",
        miniSiteLink: CRM_MINI_SITE_LINK,
        productStoreLink: "monday.com/account_products/direct_install/crm"
    },
    {
        name: "Dev",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/DEV.png",
        miniSiteLink: SOFTWARE_MINI_SITE_LINK,
        productStoreLink: "monday.com/account_products/direct_install/software"
    },
    {
        name: "Service",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/Services.png",
        miniSiteLink: SERVICE_MINI_SITE_LINK,
        productStoreLink: "monday.com/account_products/direct_install/service",
        hasBetaTag: true
    }
];
export const ADDITIONAL_PRODUCTS = [
    {
        name: "WorkForms",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/forms.png",
        miniSiteLink: WORKFORMS_HOMEPAGE_LINK
    },
    {
        name: "WorkCanvas",
        iconLink: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/canvas.png",
        miniSiteLink: CANVAS_HOMEPAGE_LINK
    }
];
