import styled from "@emotion/styled";
import { HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01, BRAND_WORK_MANAGEMENT_PRIMARY_COLOR, TRANSPARENT_COLOR_NAME } from "styles/color-consts";
import { SEPERATOR_LEFT_MARGIN, SEPERATOR_RIGHT_MARGIN } from "../sections-header-menu/constants";
const SEPERATOR_RIGHT_MARGIN_PX = `${SEPERATOR_RIGHT_MARGIN}px`;
const SEPERATOR_LEFT_MARGIN_PX = `${SEPERATOR_LEFT_MARGIN}px`;
export const StyledStructuredHeaderMenuComponent = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;

  &.with-left-panel {
    .structured-header-menu-content {
      padding-left: 64px;
    }
  }

  .header-menu-section-item-component-wrapper {
    margin-left: -8px;
  }

  &.solutions,
  &.teams {
    .header-menu-section-items-wrapper {
      margin-top: 16px;
    }
  }

  &.solutions,
  &.teams,
  &.platform {
    min-height: 592px;
  }

  &.solutions,
  &.teams,
  &.customers,
  &.resources {
    .structured-header-menu
    .structured-header-menu-top-part
    .structured-header-menu-content
    .header-menu-sections-wrapper {
      .header-menu-section-item-component-wrapper {
        margin-bottom: 10px;
      }
    }
  }

  &.customers {
    .structured-header-menu
    .structured-header-menu-top-part
    .structured-header-menu-content
    .header-menu-sections-wrapper {
      .header-menu-section-component-wrapper {
        margin-right: 90px;

        &:nth-child(2) {
          margin-right: 30px;
        }
      }
    }
  }

  &.resources {
    .structured-header-menu
    .structured-header-menu-top-part
    .structured-header-menu-content
    .header-menu-sections-wrapper {
      .header-menu-section-component-wrapper {
        margin-right: 0px;

        &:nth-child(3) {
          margin-right: 30px;
        }

        .header-menu-section-title {
          margin-right: 32px;
        }

        .header-menu-section-item-component-wrapper {
          margin-right: 60px;
        }
      }
    }
  }

  .structured-header-menu {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 32px;

    .structured-header-menu-top-part {
      display: flex;
      height: 100%;

      .structured-header-menu-content {
        width: 100%;
        display: flex;

        .header-menu-sections-wrapper {
          max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
          width: 100%;
          height: 100%;
          display: flex;
          margin: auto;

          .sections-separator {
            border-right: 1px solid;
            border-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
            margin: 0 ${SEPERATOR_RIGHT_MARGIN_PX} 0 ${SEPERATOR_LEFT_MARGIN_PX};

            &.products {
              margin-right: 80px;
            }

            &.mondayProducts {
              margin-right: 64px;
            }
          }

          .header-menu-section-component-wrapper {
            padding: 48px 0 0px;
            background-color: var(--section-background-color);
            min-height: 400px;
            margin-right: 32px;

            &.has-background-color {
              flex: 1;
              padding-left: 54px;
              padding-top: 48px;
              padding-bottom: 32px;
            }

            .header-menu-section-title {
              .header-menu-section-title-text {
                margin-bottom: 10px;
              }

              &.with-underline .header-menu-section-title-text {
                margin-bottom: 6px;
              }
            }

            .header-menu-section-item-component-wrapper {
              &.inner-menu-bottom-item {
                height: unset;
              }

              &.story {
                width: 280px;

                &:hover {
                  filter: drop-shadow(0px 8px 24px rgba(29, 140, 242, 0.08));
                }

                img {
                  height: 115px;
                }
              }

              &.feature {
                width: 286px;
                margin-right: 18px;
                margin-bottom: 10px;

                .menu-item-title-icon {
                  background-color: transparent;
                }

                &:hover {
                  .menu-item-title-icon {
                    border-color: ${Colors[TRANSPARENT_COLOR_NAME]};
                  }
                }
              }

              &.integration,
              &.addOn {
                height: 68px;
                width: 208px;

                .header-menu-section-item-component {
                  .header-menu-item-content {
                    .menu-item-title-icon {
                      margin-right: 8px;
                    }

                    &.has-image-instead-title {
                      flex-direction: row;
                      gap: 8px;

                      .picture-component {
                        display: flex;
                        width: 40px;
                        height: 40px;
                        border: 1px solid ${Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]};
                        border-radius: 4px;
                      }
                    }
                  }

                  svg {
                    border: 1px solid var(--icon-stroke-color);
                    border-radius: 4px;
                  }
                }
              }
            }

            .header-menu-section-item-component {
              .header-menu-item-content {
                .menu-item-image-and-caption-wrapper {
                  position: relative;

                  .menu-item-image-floating-caption {
                    position: absolute;
                    bottom: 12px;
                    left: 8px;
                    padding: 4px;
                    background-color: ${Colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]};
                    font-size: 12px;
                    border-radius: 4px;
                    color: white;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    &.solutions,
    &.teams,
    &.platform {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-left-panel {
            min-width: 304px;
          }

          .structured-header-menu-content {
            padding-left: 40px;

            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                &.features {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }

    &.solutions,
    &.teams {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    &.customers,
    &.resources {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                margin-right: 10px;

                .header-menu-section-component {
                  .header-menu-section-items-wrapper {
                    .header-menu-section-item-component-wrapper {
                      margin-right: 32px;
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.customers {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                margin-right: 20px;

                &:nth-child(2) {
                  margin-right: 52x;
                }

                .header-menu-section-component {
                  .header-menu-section-items-wrapper {
                    .header-menu-section-item-component-wrapper {
                      margin-right: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH}) {
    &.platform {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .header-menu-section-item-component-wrapper {
            &.inner-menu-bottom-item {
              height: 120px;

              .menu-item-description {
                -webkit-line-clamp: 3;
              }
            }
          }
        }
      }
    }

    &.solutions,
    &.teams,
    &.platform {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            padding-left: 38px;
          }

          .structured-header-menu-left-panel {
            min-width: 250px;

            .structured-header-menu-left-panel-items {
              .inner-menu-item {
                .inner-menu-item-title {
                  font-size: 0.875rem;
                }

                .inner-menu-item-subitems {
                  .inner-menu-item-subitem {
                    font-size: 0.875rem;

                    .inner-menu-item-subitem-title-and-icon {
                      gap: 8px;
                    }
                  }
                }
              }
            }

            .header-menu-section-item-component-wrapper {
              &.inner-menu-bottom-item {
                width: 216px;
              }
            }
          }
        }
      }
    }

    &.customers,
    &.resources {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                margin-right: 16px;

                .header-menu-section-component {
                  .header-menu-section-items-wrapper {
                    .header-menu-section-item-component-wrapper {
                      width: 274px;
                      margin-right: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.customers {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                &:first-child {
                  margin-right: 20px;
                }

                &:nth-child(2) {
                  margin-right: 22px;
                }

                .header-menu-section-component {
                  .header-menu-section-items-wrapper {
                    .header-menu-section-item-component-wrapper {
                      margin-right: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.resources {
      .structured-header-menu {
        .structured-header-menu-top-part {
          .structured-header-menu-content {
            .header-menu-sections-wrapper {
              .header-menu-section-component-wrapper {
                margin-right: 26px;

                &:nth-child(3) {
                  margin-right: 38px;
                }
              }
            }
          }
        }
      }
    }

    .structured-header-menu {
      .structured-header-menu-top-part {
        .structured-header-menu-content {
          .header-menu-sections-wrapper {
            .header-menu-section-component-wrapper {
              &.has-background-color:last-child {
                padding-left: 32px;
              }
            }
          }
        }
      }
    }
  }
`;
