import styled from "@emotion/styled";
import Colors from "styles/colors";
import { hexToRgba } from "utils/general";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
const BACKGROUND_COLOR_OPACITY = 0.08;
export const StyledTags = styled.div`
  display: flex;
  align-content: center;

  .tags-title-wrapper {
    margin-right: 8px;
    align-self: center;
  }

  .tags-wrapper {
    display: flex;
    gap: 4px;

    .tag {
      border-radius: 4px;
      padding: 4px 8px;
      color: ${({ color })=>Colors[color]};
      background-color: ${({ color })=>hexToRgba(Colors[color || BRAND_BLACK_TEXT_COLOR], BACKGROUND_COLOR_OPACITY)};
    }
  }
`;
