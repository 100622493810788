import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledStructuredHeaderMenuContentComponent = styled.div`
  display: flex;
  flex-direction: column;

  .structured-header-menu-content-title {
    font-size: 1rem;
    color: ${Colors.asphalt};
    padding-top: 48px;

    &:after {
      content: "";
      background: #323338;
      opacity: 0.1;
      top: 6px;
      display: block;
      position: relative;
      height: 1px;
      margin-left: -8px;
      width: calc(100% - 40px);
    }
  }

  .header-menu-section-component-wrapper {
    .header-menu-sections-wrapper {
      display: flex;

      .header-menu-section-title {
        font-size: 1rem;
        font-weight: 300;
      }
    }

    &.integrations,
    &.addOns {
      .header-menu-section-items-wrapper {
        margin: 8px 0 32px;
      }

      .header-menu-section-title {
        font-size: 0.8125rem;
        font-weight: 400;

        * {
          font-weight: 400;
        }
      }
    }
  }
`;
